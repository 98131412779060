import React from 'react';
import {
  SEO,
  Layout,
  Header,
  InfoSection,
  TheAircraft,
  Footer,
  TakeOff,
  AirTime,
  Landing,
} from '../components';

export default ({ pageContext: ctx }) => {
  const {
    heroSection,
    infoSection,
    aircraftSection,
    takeoff,
    airtime,
    landing,
    metadata,
    translationPath,
    i18n_lang: lang,
  } = ctx;

  console.log({ ctx });

  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header {...heroSection} lang={lang} translationPath={translationPath} />
      <InfoSection {...infoSection} lang={lang} />
      <TheAircraft {...aircraftSection} lang={lang} />
      <TakeOff {...takeoff} lang={lang} />
      <AirTime
        {...airtime}
        lang={lang}
        video="https://www.youtube.com/watch?v=DocXo3gqGdI&ab_channel=JasonLengstorf"
      />
      <Landing {...landing} lang={lang} />
      <Footer lang={lang} />
    </Layout>
  );
};
